<template>
    <div>
      <Home_navbar HeaderClass="fixed-top"></Home_navbar>
      <!-- Breadcrumbs section starts here  -->
      <section class="breadcrumbs">
        <div class="container">   
          <div class="d-flex justify-content-between align-items-center">
             <h2>Online | Trainings</h2>
            <ol>
              <li><router-link to="/">Home</router-link></li>
              <li>Online classes</li>
            </ol>
          </div>  
        </div>
      </section>

      <!-- ======= Article Section Main & Sidebar ======= -->
      <section class="blog">
        <div class="container">
          <div class="row">
            <!-- The main article section starte here -->
            <div id="ThiraCard" class="col-lg-8">
                <!-- Portfolio Search Box & Add Button-->
                <div>
                  <b-row>
                    <b-col sm="8"><b-form-input v-model="profsearch" placeholder="Search by Art forms"></b-form-input></b-col>
                      <b-col sm="4" >
                      <p class="tag-rec-cnt" v-if="TotRows>50"> Showing 50 of {{TotRows}}</p>
                      <p class="tag-rec-cnt" v-if="TotRows<50"> Showing {{TotRows}} records</p>                      
                    </b-col>
                  </b-row>
                </div>
                <div class="p-2">
                  <b-row class="mt-3">
                    <b-col sm="6 p-2" v-for="Data in AdmSearchList.slice(0, 6)" v-bind:key="Data.seq_no">     
                      <div class="recent-perform" >
                        <router-link :to="{name:'online-classes-detail', params:{ SeqNo:Data.seq_no, SlugName:Data.slug_name}}"><img class="float-left" v-bind:src="Data.logo" :alt="Data.promo_title"></router-link>
                        <h4><router-link :to="{name:'online-classes-detail', params:{ SeqNo:Data.seq_no, SlugName:Data.slug_name}}">{{Data.promo_title}}</router-link></h4>
                        <p class="tag-line">{{Data.promo_type}} By {{Data.owner_id}} </p>
                      </div> 
                    </b-col>
                  </b-row>
                </div>
                <b-pagination pills class="mt-5" v-show="TotRows>50"
                  v-model="currentPage"
                  :total-rows="TotRows"
                  :per-page="PerPage"
                  aria-controls="ThiraCard"
                  @change="handlePageChange"
                  align="center"
                  size="md"
                ></b-pagination>                  
            </div>
            <!-- Article Right side bar section starts here -->
            <div class="col-lg-4">
              <div class="sidebar">
                <!-- sidebar recent posts-->
                <SideRecentPerformers/>
                <!-- sidebar Filter By Performers Category-->
                <SideFilterPerformers/>  
              </div>
            </div>
            <!-- Article Right side bar Ends here -->
          </div><!-- End row -->
        </div><!-- End container -->
      </section><!-- End Blog Section -->        

      <!-- ======= Inject Footer section here  ======= -->
      <Home_footer/>
    
    </div><!-- End Main Div  -->
</template>

<script>
import Vue from 'vue'
import Vueaxios from 'vue-axios'
import axios from 'axios'
import { CardPlugin } from 'bootstrap-vue'

Vue.use(CardPlugin)
Vue.use(Vueaxios,axios)

  export default {
  name:"OnlineClasses",
  metaInfo: {
    title: "Thiraseela :: ",
    titleTemplate: "%s Dance-Music-Performing Arts Online classes | Artists Portfolios | Book Stage Shows | Online Program Booking"
  },      
    data() {
      return {
        profsearch: "",
        items: [],
        RespOnlineClasses: [],
        // Pagination Variables
        CurrPage: 1,
        MaxPage: 0,
        TotRows: 0,
        PerPage: 50,
        currentPage: 1,
        More50Rec: false,
      }
    },
    watch: {
       '$route' (to)
       {
        if(this.$route.params.Maincatg == 'All') {
          this.ReadOnlineClasses()
        }
        else {
          this.OnlineClassesByCatg(to.params.Maincatg)
        }                 
       }
    },      
    mounted()
    {
      this.GetTotRecCnt()
      if(this.$route.params.Maincatg == 'All') {
        this.ReadOnlineClasses()
      } 
      else {
        this.OnlineClassesByCatg(this.$route.params.Maincatg)
      }           
    },
    methods: {
      ReadOnlineClasses(){
        const REQformData1 = new FormData();
        REQformData1.append('Page_No', this.CurrPage);        
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=ReadOnlineClasses',REQformData1)
        .then((resp)=>{
        // this.gallery=resp.data.EventsGalleryMain;
        resp.data.OnlineClasses.forEach(element => this.RespOnlineClasses.push(element))
      })
      },

      OnlineClassesByCatg(Maincatg){     
        const REQformData = new FormData();
        REQformData.append('Maincatg', Maincatg);
        REQformData.append('Page_No', this.CurrPage);              
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=OnlineClassesByCatg', REQformData)
        .then((resp)=>{
        resp.data.OnlineClasses.forEach(element => this.RespOnlineClasses.push(element))
        // this.RespArray=resp.data.AllAcademy;
      })
      },      
      
      GetTotRecCnt(){
        const REQformData = new FormData();
        REQformData.append('table_name', 'promotion_main');          
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=GetTotRecCnt',REQformData)
        .then((resp)=>{
        const FirstObject = resp.data.GetTotRecCnt[0];
        this.TotRows = FirstObject.Tot_Rec_Cnt;
        // console.log('TotRows',this.TotRows)
      })
      },
      handlePageChange(value) {
      this.CurrPage = value;
      document.body.scrollIntoView();
      // console.log('this.CurrPage',this.CurrPage)
      if (this.CurrPage > this.MaxPage) 
        {
          this.ReadOnlineClasses();
          this.MaxPage = this.CurrPage
        }
      // console.log('this.MaxPage',this.MaxPage)
      },             
    },
    computed: {
    RespSearchList: function(){
        return this.RespOnlineClasses.filter((RespOnlineClasses) => {
        return RespOnlineClasses.catg_main.toLowerCase().match(this.profsearch.toLowerCase())
      });
    },
      AdmSearchList: function()
        {
          return this.RespSearchList.slice(
          (this.currentPage - 1) * this.PerPage,
          this.currentPage * this.PerPage,
          );
        }        
  }
  }
</script>

<style scoped>
.recent-perform img {
    width: 120px;
    float: center;
    height: 120px;
    border-radius: 15px;
    padding-right: 7px;

}
.recent-perform h4 {
    font-size: 15px;
    font-weight: bold;
}

.recent-perform h4 a {
    color: #e75480;
    transition: 0.3s;
}

.recent-perform h4 a:hover {
    color: #ff7300;
}

.recent-perform .tag-line {
    display: block;
    /* margin-left: 89px;
    margin-top: -5px; */
    font-style: italic;
    font-size: 14px;
    color: #72afce;
}
</style>